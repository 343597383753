import { htmlToElement, toTemplate } from "./util";
import { enhancer as photoSwipeEnhancer } from "./photoswipe";

export const SystemsDashboardInfo = ({ template, data }) => {
  let node;

  const getElement = (target) =>
    node.querySelector(`[data-element="${target}"]`);

  /**
   * Set header media.
   */
  const setHeader = (vimeoUrl, fallbackImage) => {
    if (vimeoUrl) {
      const iframeNode = htmlToElement(
        `<iframe width="331px" height="190px" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen src="${vimeoUrl}" frameborder="0"></iframe>`
      );

      if (!getElement("figure")) {
        return;
      }
      if (getElement("cookie-consent")) {
        getElement("cookie-consent").setAttribute("data-visible", "true");
      }
      getElement("figure").setAttribute("data-visible", "true");
      getElement("figure").innerHTML = iframeNode ? iframeNode.outerHTML : "";
      return;
    }

    if (fallbackImage) {
      const url = fallbackImage.src;
      const imageNode = htmlToElement(
        `<img src="${url}" width="331px" height="190px" alt="" decoding="async"/>`
      );

      if (getElement("cookie-consent")) {
        getElement("cookie-consent").setAttribute("data-visible", "false");
      }

      if (getElement("figure")) {
        getElement("figure").setAttribute("data-visible", "true");
        getElement("figure").innerHTML = imageNode ? imageNode.outerHTML : "";
      }
      return;
    }

    getElement("cookie-consent").setAttribute("data-visible", "false");
    getElement("figure").setAttribute("data-visible", "false");
  };

  /**
   * Set images in gallery.
   */
  const setImages = (images) => {
    getElement("mediaImages").innerHTML = images.reduce((acc, image) => {
      return `
        ${acc}
        <li>
          <figure class="js-gallery-item">
            <a href="${image.src}" data-original-image="${image.src}" data-size="1920x1280" data-srcset="${image.srcset}" aria-label="Enlarge image">
              <img src="${image.thumbnail}" alt="${image.alt}" data-caption="${image.caption}" decoding="async"/>
            </a>
          </figure>
        </li>
      `;
    }, "");
    getElement("mediaImages").parentNode.setAttribute(
      "aria-hidden",
      !images.length
    );
    photoSwipeEnhancer(getElement("mediaImages"));
  };

  /**
   * Set videos in gallery.
   */
  const setVideos = (videos) => {
    if (getElement("mediaVideos")) {
      getElement("mediaVideos").innerHTML = videos.reduce((acc, video) => {
        const videoHTML = `<iframe
          class='video-iframe-embed__iframe'
          src='${video.url}'
          data-cookie-consent-accepted='optimal'
          frameborder='0'
          data-lazyload='false'
      ></iframe>`;

        return `
          ${acc}
          <li>
            <a href="${video.url}" data-handler="videoOverlay" data-video-html="${videoHTML}" aria-label="Enlarge video">
              <div>
                <figure>
                  <img src="${video.image.url}" alt="${video.image.alt}" decoding="async"/>
                  <svg role="presentation" aria-hidden="true" width="134" height="134" viewBox="0 0 134 134" xmlns="http://www.w3.org/2000/svg"><title>Play icon</title><path d="M67 11.167c30.787 0 55.833 25.047 55.833 55.833 0 30.787-25.046 55.833-55.833 55.833-30.786 0-55.833-25.046-55.833-55.833 0-30.786 25.047-55.833 55.833-55.833zM67 0C30 0 0 30 0 67s30 67 67 67 67-30 67-67S104 0 67 0zM50.25 94.917V39.083l50.25 28.732-50.25 27.102z" fill-rule="evenodd"/></svg>
                </figure>
              </div>
              <span>${video.title}</span>
            </a>
          </li>
        `;
      }, "");

      getElement("mediaVideos").parentNode.setAttribute(
        "aria-hidden",
        !videos.length
      );
    }
  };

  /**
   * Show the stay connected section.
   */
  const setShowStayConnected = (showStayConnected) => {
    const section = getElement("stay-connected");
    if (showStayConnected) {
      section.setAttribute("data-visible", "true");
      section.setAttribute("aria-hidden", "false");
    } else {
      section.setAttribute("data-visible", "false");
      section.setAttribute("aria-hidden", "true");
    }
  };

  /**
   * Set all variables in text content.
   */
  const setTextVariables = () => {
    node = htmlToElement(toTemplate(template, data));
  };

  /**
   * Update the visibility of the links by setting a type on the container.
   * Visibility is set by CSS.
   */
  const updateLinkVisibility = () => {
    const linksContainer = getElement("links-container");

    if (linksContainer) {
      linksContainer.setAttribute("data-system-type", data.system_type);
    }
  };

  const render = () => {
    setTextVariables(data);
    setHeader(data.vimeo_url, data.vimeo_fallback_image);
    setVideos(data.videos);
    setImages(data.images);
    setShowStayConnected(data.showStayConnected);
    updateLinkVisibility();

    if (!data.videos.length && !data.images.length) {
      getElement("media").setAttribute("aria-hidden", "true");
    }
    if (!data.videos.length) {
      getElement("videos").setAttribute("aria-hidden", "true");
    }
    if (!data.images.length) {
      getElement("images").setAttribute("aria-hidden", "true");
    }
  };

  return {
    node,
    children() {
      render();
      return node.childNodes;
    },
  };
};
