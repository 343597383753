import { map } from "./util";

const siblings = (el) =>
  Array.from(el.parentNode.children).filter((child) => child !== el);

const toggleExpandedClass = (el) => {
  const panels = el.querySelectorAll("li");

  map(panels, (panel) => {
    const button = panel.querySelector(".js-button");
    button.addEventListener("click", (e) => {
      el.classList.add("is-active");
      siblings(panel).map((sibling) => sibling.classList.remove("is-expanded"));
      if (panel.classList.contains("is-expanded")) {
        panel.classList.remove("is-expanded");
      } else {
        panel.classList.add("is-expanded");
      }
      siblings(panel).map((sibling) =>
        sibling.setAttribute("aria-selected", false)
      );
      panel.setAttribute(
        "aria-selected",
        panel.classList.contains("is-expanded")
      );
    });
  });
};

export const enhancer = (el) => {
  toggleExpandedClass(el);
};
