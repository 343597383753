/* eslint-disable no-use-before-define, no-restricted-globals */

import * as ibantools from "ibantools";
import EmailValidator from "email-validator";
import {
  debounce,
  forEach,
  htmlToElement,
  parseJSON,
  selfOrClosest,
} from "./util";
import {
  addToCart as gtmAddToCart,
  checkout as gtmCheckout,
  checkoutOptions as gtmCheckoutOptions,
} from "./donate-gtm";
import { PayPalForm } from "./donate-form-paypal";

const PAYPAL_RETURN_URL_ATTRIBUTE = "data-paypal-return-url";

const STEP_ATTRIBUTE = "data-step";
const TO_STEP_ATTRIBUTE = "data-to-step";
const VISIBLE_ATTRIBUTE = "data-visible-when";
const ONLY_ONE_ATTRIBUTE = "data-only-one-of-children-allowed";
const VALIDATION_ATTRIBUTE = "data-validate";

const CURRENCY_NAME = "currency";
const FREQUENCY_NAME = "frequency";
const AMOUNT_NAME = "amount";
const AMOUNT_CUSTOM_NAME = "amount_custom";
const PAYMENT_NAME = "paymentmethod";
const COUNTRY_NAME = "country";
const MAX_AMOUNT = 50000;

const NAME_MAPPER = {
  [CURRENCY_NAME]: CURRENCY_NAME,
  [FREQUENCY_NAME]: FREQUENCY_NAME,
  [AMOUNT_NAME]: AMOUNT_NAME,
  [AMOUNT_CUSTOM_NAME]: AMOUNT_CUSTOM_NAME,
  [PAYMENT_NAME]: PAYMENT_NAME,
  [COUNTRY_NAME]: COUNTRY_NAME,
};

const DonateForm = (container) => {
  const state = {
    step: 1,
    submitting: false,
  };

  const form = container.querySelector("form");
  const stepsElement = document.querySelector(".js-steps");
  const totalStepsElement = document.querySelector(".js-total-step");
  const currentStepElement = document.querySelector(".js-current-step");
  const monthlyPreselectAmount = document.querySelector(
    '[data-currency-preselect="monthly"]'
  );
  const oneTimePreselectAmount = document.querySelector(
    '[data-currency-preselect="one-time"]'
  );

  const setState = (key, value) => {
    state[key] = value;
  };

  const getState = (key) => (key ? state[key] : state);

  const isTag = (input, name) =>
    input.tagName && input.tagName.toLowerCase() === name;

  const isType = (input, type) => input.getAttribute("type") === type;

  const isInteger = (value) => {
    return (
      typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  };

  const isRegularInput = (input) => {
    const type = input.getAttribute("type");
    return (
      type !== "checkbox" &&
      type !== "radio" &&
      type !== "button" &&
      type !== "submit" &&
      type !== "hidden"
    );
  };

  const getIterableInputOrChildInputs = (el) => {
    return isTag(el, "input") || isTag(el, "select")
      ? [el]
      : [...el.querySelectorAll("input, select")];
  };

  const shouldBeToggled = (conditions) => {
    return Object.keys(conditions).every((key) => getState(key) !== undefined);
  };

  const shouldBeVisible = (conditions) => {
    return Object.keys(conditions).every(
      (key) => getState(key) === conditions[key]
    );
  };

  const validateAmount = (value) => {
    const integerCheck = {
      isValid: isInteger(parseInt(value, 10)) && parseInt(value, 10) > 0,
      message: "Only rounded numeric values are accepted.",
    };
    if (!integerCheck.isValid) {
      return integerCheck;
    }
    const amountCheck = {
      isValid: integerCheck.isValid && parseInt(value, 10) <= MAX_AMOUNT,
      message:
        "You have entered a very generous amount, which we cannot support through the website. If you would like to make this donation, <a target='_blank' href='/faq/can-i-transfer-an-amount-directly-to-your-bank-account/'>please read how to make a manual bank/wire transfer here</a>. Thank you!",
    };
    return amountCheck;
  };

  const validateIBAN = (value) => ({
    isValid: ibantools.isValidIBAN(value.replace(/\s/g, "")),
    message: "Provide a valid IBAN.",
  });

  const validateBic = (value) => ({
    isValid: ibantools.isValidBIC(value.replace(/\s/g, "")),
    message: "Provide a valid BIC.",
  });

  const validateEmail = (value) => ({
    isValid: EmailValidator.validate(value),
    message:
      "Please provide a valid email address, which will be used for follow-up on your payments (receipts, error messages, etc).",
  });

  const validateRadioGroup = (group) => ({
    isValid: group.some((input) => input.checked),
    message: "Choosing one of these options is required.",
  });

  const validateRegularInput = (value) => ({
    isValid: value !== "",
    message: "This field is required.",
  });

  const validateInput = (input) => {
    if (isType(input, "radio")) {
      const groupInputs = form.querySelectorAll(
        `[required][name="${input.getAttribute("name")}"]`
      );
      return validateRadioGroup([...groupInputs]);
    }
    switch (input.getAttribute(VALIDATION_ATTRIBUTE)) {
      case "amount":
        return validateAmount(input.value);
      case "iban":
        return validateIBAN(input.value);
      case "bic":
        return validateBic(input.value);
      case "email":
        return validateEmail(input.value);
      default:
        return validateRegularInput(input.value);
    }
  };

  const isValidStep = () => {
    const inputs = getStepElement().querySelectorAll(
      "input[required], select[required]"
    );
    return [...inputs].every((input) => validateInput(input).isValid);
  };

  const addError = ({ input, errorLocation, message }) => {
    input.setAttribute("aria-invalid", "true");
    input.setAttribute("aria-describedby", `${input.getAttribute("id")}-error`);
    if (errorLocation.classList.contains("js-error")) {
      return;
    }
    const error = htmlToElement(`
      <ul class="error js-error" role="alert" id="${input.getAttribute(
        "id"
      )}-error">
        <li>${message}</li>
      </ul>
    `);
    errorLocation.parentNode.insertBefore(error, errorLocation);
  };

  const removeError = ({ input, errorLocation }) => {
    input.removeAttribute("aria-invalid");
    input.removeAttribute("aria-describedby");
    if (!errorLocation || !errorLocation.classList.contains("js-error")) {
      return;
    }
    // Only remove errors from the input itself, leave others as be
    if (
      input.getAttribute("id") !==
      errorLocation.getAttribute("id").replace("-error", "")
    ) {
      return;
    }
    errorLocation.parentNode.removeChild(errorLocation);
  };

  const toggleInputError = (input, removeOnly) => {
    const fieldset = selfOrClosest(input, `[aria-labelledby]`);
    const legend = fieldset
      ? form.querySelector(`#${fieldset.getAttribute("aria-labelledby")}`)
      : undefined;
    const label = form.querySelector(`[for="${input.getAttribute("id")}"]`);
    const errorLocation =
      (fieldset && !label) || (fieldset && input.nextElementSibling === label)
        ? legend.nextElementSibling
        : label.nextElementSibling;
    const validatedInput = validateInput(input);
    if (validatedInput.isValid || !input.hasAttribute("required")) {
      removeError({ input, errorLocation });
    } else if (!removeOnly) {
      addError({ input, errorLocation, message: validatedInput.message });
    }
  };

  const getStepElement = () =>
    form.querySelector(`[data-step="${getState("step")}"]`);

  const toggleErrors = (removeOnly = false) => {
    const requiredInputs = getStepElement().querySelectorAll("input, select");
    forEach([...requiredInputs], (input) =>
      toggleInputError(input, removeOnly)
    );
  };

  const setStep = (index) => {
    const safeIndex = parseInt(index, 10);
    if (safeIndex > getState("step") && !isValidStep()) {
      toggleErrors();
      return;
    }
    setState("step", safeIndex);
    forEach(form.querySelectorAll(`[${STEP_ATTRIBUTE}]`), (step) => {
      if (parseInt(step.getAttribute(STEP_ATTRIBUTE), 10) === safeIndex) {
        step.removeAttribute("aria-hidden");
        step.querySelector("input").focus();
        return;
      }
      step.setAttribute("aria-hidden", "true");
    });
  };

  const uncheckElementOrChildren = (el) => {
    forEach(getIterableInputOrChildInputs(el), (input) => {
      if (isType(input, "radio")) {
        input.checked = false;
      } else if (isTag(input, "select")) {
        input.selectedIndex = 0;
      } else if (!isType(input, "hidden") && !isType(input, "checkbox")) {
        input.value = "";
      }
    });
  };

  const unmarkElementOrChildrenRequired = (el) => {
    forEach(getIterableInputOrChildInputs(el), (input) =>
      input.removeAttribute("required")
    );
  };

  const markElementOrChildrenRequired = (el) => {
    forEach(getIterableInputOrChildInputs(el), (input) =>
      input.setAttribute("required", "")
    );
  };

  const toggleElementVisibility = (el) => {
    const conditions = parseJSON(el.getAttribute(VISIBLE_ATTRIBUTE));
    if (!shouldBeToggled(conditions)) {
      return;
    }
    const shouldHide = !shouldBeVisible(conditions);
    el.setAttribute("aria-hidden", shouldHide);
    if (shouldHide) {
      uncheckElementOrChildren(el);
      unmarkElementOrChildrenRequired(el);
    } else {
      markElementOrChildrenRequired(el);
    }
  };

  /**
   * Toggle children of a parent group, where only one value/selection is allowed
   */
  const toggleExclusiveChildren = (originalInput) => (el) => {
    const inputs = el.querySelectorAll("input");
    const valueMatches = [...inputs].filter((input) => {
      if (input.tagName.toLowerCase() === "radio" && !input.checked) {
        return false;
      }
      return input.value === state[NAME_MAPPER[input.name]];
    });
    const originalMatch = valueMatches.filter(
      (input) => input === originalInput
    );
    const matches = originalMatch.length ? originalMatch : valueMatches;
    forEach(inputs, (input) => {
      if (
        !matches.length ||
        matches[0].getAttribute("aria-hidden") === "true" ||
        matches[0] === input
      ) {
        markElementOrChildrenRequired(input);
      } else {
        uncheckElementOrChildren(input);
        unmarkElementOrChildrenRequired(input);
      }
    });
  };

  const updateState = (el) => {
    const name = el.getAttribute("name");
    const value = isTag("select")
      ? el.options[el.selectedIndex].value
      : el.value;
    if (!value || !NAME_MAPPER[name]) {
      return;
    }
    setState([NAME_MAPPER[name]], value);
    // Reset `amount` or `amount_custom` when the other is filled
    if (name === AMOUNT_NAME || name === AMOUNT_CUSTOM_NAME) {
      const otherName = name === AMOUNT_NAME ? AMOUNT_CUSTOM_NAME : AMOUNT_NAME;
      setState([NAME_MAPPER[otherName]], undefined);
    }
    // Preselect currency, only when the frequency is updated.
    if (el.name === "frequency") {
      preselectCurrency();
    }
  };

  const updateInterface = (originalInput) => {
    forEach(
      container.querySelectorAll(`[${VISIBLE_ATTRIBUTE}]`),
      toggleElementVisibility
    );
    forEach(
      container.querySelectorAll(`[${ONLY_ONE_ATTRIBUTE}]`),
      toggleExclusiveChildren(originalInput)
    );
    toggleErrors(true);

    // Small hack for custom amount to show it is the current choice when filled.
    const customAmountEl = container.querySelector(
      `[name="${AMOUNT_CUSTOM_NAME}"]`
    );
    if (state.amount_custom && customAmountEl.required) {
      customAmountEl.classList.add("is-filled");
    } else {
      customAmountEl.classList.remove("is-filled");
    }

    // Update steps donut-chart and text.
    const totalSteps = state.currency === "EUR" ? 3 : 2;
    stepsElement.setAttribute("data-total-steps", totalSteps);
    stepsElement.setAttribute("data-current-step", state.step);
    totalStepsElement.innerHTML = totalSteps;
    currentStepElement.innerHTML = state.step;
  };

  /**
   * Preselect the currency based on the frequency.
   */
  const preselectCurrency = () => {
    if (state.frequency === "one") {
      oneTimePreselectAmount.checked = true;
      setState([NAME_MAPPER["amount"]], oneTimePreselectAmount.value);
    } else {
      monthlyPreselectAmount.checked = true;
      setState([NAME_MAPPER["amount"]], monthlyPreselectAmount.value);
    }
  };

  const handleInputEvent = (e) => {
    updateState(e.target);
    updateInterface(e.target);
  };

  const handleButtonEvent = (e) => {
    const button = e.target;
    const step = button.getAttribute(TO_STEP_ATTRIBUTE);
    if (isType(button, "submit") || !step) {
      return;
    }
    setStep(step);
    updateInterface();
  };

  const handleLabelEvent = (e) => {
    const label = selfOrClosest(e.target, "label");
    window.setTimeout(
      () => form.querySelector(`#${label.getAttribute("for")}`).blur(),
      0
    );
  };

  const handleClickEvent = (e) => {
    if (isTag(e.target, "button")) {
      handleButtonEvent(e);
    } else if (selfOrClosest(e.target, "label")) {
      handleLabelEvent(e);
    }
  };

  const handleKeyUpEvent = (e) => {
    if (!isRegularInput(e.target)) {
      return;
    }
    debounce(handleInputEvent, 200)(e);
  };

  const submit = () => {
    form.removeEventListener("submit", handleSubmitEvent);
    if (state.currency === "USD") {
      const returnUrl = container.getAttribute(PAYPAL_RETURN_URL_ATTRIBUTE);
      const paypal = PayPalForm({ state, returnUrl });
      paypal.createAndSubmitForm();
    } else {
      form.submit();
    }
  };

  const handleSubmitEvent = (e) => {
    e.preventDefault();
    if (!isValidStep()) {
      toggleErrors();
    } else if (state.step === 1 && state.currency === "EUR") {
      setStep(2);
      updateInterface();
      gtmAddToCart(state);
      gtmCheckout(state);
    } else {
      if (state.currency === "USD") {
        gtmAddToCart(state);
        gtmCheckout(state);
      }
      gtmCheckoutOptions(state).then(submit);
      setState("submitting", true);
      updateInterface();
    }
  };

  /**
   * Update elements with a initially default value.
   * Check initially checked input (and notify state and interface about it).
   */
  const setInitialDefaults = (input) => {
    if (input.getAttribute("data-initially-checked")) {
      input.checked = true;
      updateState(input);
      updateInterface(input);
    }

    // Check currency and update state/UI.
    if (input.getAttribute("data-currency-preselect")) {
      oneTimePreselectAmount.checked = true;
      updateState(input);
      updateInterface(input);
    }
  };

  return {
    init() {
      // Add novalidate attribute since we're doing it ourselfs
      form.setAttribute("novalidate", "");

      // Change events, such as radio buttons and regular inputs
      form.addEventListener("change", handleInputEvent);

      // Key events, but only for regular inputs
      form.addEventListener("keyup", handleKeyUpEvent);

      // Click event for blurring styled radio buttons
      form.addEventListener("click", handleClickEvent);

      // Submit event
      form.addEventListener("submit", handleSubmitEvent);

      // Reset all inputs (eg. when going `history.back()`)
      const inputs = [...form.querySelectorAll("input, select")].filter(
        (input) => {
          // Do not reset country or currency,
          // since it's prefilled via the microservice.
          return input.name !== "country" || input.name !== "currency";
        }
      );
      inputs.forEach(uncheckElementOrChildren);
      inputs.forEach(setInitialDefaults);
    },
  };
};

export const enhancer = (el) => {
  const donateForm = DonateForm(el);
  donateForm.init();
};
