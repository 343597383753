import { subscribe } from "./observer";
import { systemsDashboardReceivedData } from "./observer-subjects";
import formatDashboardNumber from "../utils/format-dashboard-number";

/**
 * Update contents of the totals based on the received realtime data.
 */
const updateTotals = (elements, totals) => {
  elements.lastDays.innerHTML = formatDashboardNumber(
    totals.debris_extracted_last_30d
  );
  elements.total.innerHTML = formatDashboardNumber(
    totals.debris_extracted_total
  );
};

/**
 * Create a HTML status element based on the template.
 */
const createStatusElement = (elements, item) => {
  const { status, riverCount, oceanCount, label } = item;
  // Create clone of template.
  const clone = elements.interceptorsTemplate.content.cloneNode(true);

  // Get all relevant HTML element.
  const statusElement = clone.querySelector(".js-status");
  const interceptorsElement = clone.querySelector(".js-interceptors");
  const oceansElement = clone.querySelector(".js-oceans");

  // Update the content values.
  statusElement.innerHTML = label;
  statusElement.setAttribute("data-status", status);

  const interceptorsLabel = riverCount > 1 ? "Interceptors" : "Interceptor";
  interceptorsElement.innerHTML = `${riverCount} ${interceptorsLabel}`;
  interceptorsElement.setAttribute("data-visible", !!riverCount);

  const oceanSystemsLabel = oceanCount > 1 ? "Ocean systems" : "Ocean system";
  oceansElement.innerHTML = `${oceanCount} ${oceanSystemsLabel}`;
  oceansElement.setAttribute("data-visible", !!oceanCount);

  // Return a status element with the correct data.
  return clone;
};

/**
 * Create a status element for each relevant interceptor status.
 */
const createStatusElements = (elements, labels, systems) => {
  // Get all unique statuses from all interceptors.
  const uniqueStatuses = systems
    .map((system) => system.status || "status unknown") // Edge case if an interceptor has no status.
    .filter((v, i, a) => a.indexOf(v) === i);

  // Get specific systems
  const riverSystems = systems.filter(
    (system) => system.system_type === "River"
  );
  const oceanSystems = systems.filter(
    (system) => system.system_type === "Ocean"
  );

  // Create a HTML element for each unique status.
  uniqueStatuses
    .map((status) => {
      // Get matching systems.
      const matchingSystems = (specificSystems) =>
        specificSystems.filter((system) => {
          // Edge case if an interceptor has no status.
          return status === "status unknown"
            ? system.status === undefined
            : system.status === status;
        });

      return {
        status,
        riverCount: matchingSystems(riverSystems).length,
        oceanCount: matchingSystems(oceanSystems).length,
        label: labels[status] || status,
      };
    })
    .map((item) => createStatusElement(elements, item))
    .map((statusElement) => elements.statusesList.appendChild(statusElement));
};

/**
 * Called by Observer.
 * Update contents of HTML with the received data.
 */
const handleReceiveData = (elements, labels) => ({ totals, systems }) => {
  updateTotals(elements, totals);
  createStatusElements(elements, labels, systems);
};

export const enhancer = (container) => {
  const elements = {
    lastDays: container.querySelector(".js-last-days"),
    total: container.querySelector(".js-total"),
    interceptorsTemplate: container.querySelector(".js-interceptors-template"),
    statusesList: container.querySelector(".js-statuses-list"),
  };

  const labels = JSON.parse(container.getAttribute("data-status-labels"));

  subscribe(systemsDashboardReceivedData, handleReceiveData(elements, labels));
};
