import { publish, subscribe } from "../observer";
import {
  trashTrackerUpdateData,
  trashTrackerResetData,
  trashTrackerIsDoneDrawing,
} from "../observer-subjects";

let popup = null;

/**
 * create a recursively calling function to draw the source point by point.
 * Draws the line in bulk determined by the amount of Array inside the data<Array>
 * @param {number} count
 * @param {array} trajectory | Array of Arrays: [[[long, lat], [long, lat]], [[long, lat], [long, lat]]]
 * @param {array} coordinates | Array of Arrays: [[[long, lat], [long, lat]], [[long, lat], [long, lat]]]
 * @param {object} source Source instance of MapBox
 */
const draw = (count, trajectory, coordinates, source, map, speed, resolve) => {
  // Bail out at the end of the array
  if (count >= trajectory.length) {
    resolve();
    return;
  }

  // Add a new point to the dataset for the map
  const newCoordinates = [...coordinates, ...trajectory[count]];

  const features = [
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: newCoordinates,
      },
    },
  ];

  // Update the data on the map.
  source.setData({
    type: "FeatureCollection",
    features,
  });

  if (count % 10 === 0) {
    const lastInArrayIndex = trajectory[count].length - 1;

    map.flyTo({
      speed,
      center: trajectory[count][lastInArrayIndex],
      essential: false,
    });
  }

  // Recursively call itself after a delay to promote the drawing of the line.
  setTimeout(() => {
    draw(count + 1, trajectory, newCoordinates, source, map, speed, resolve);
  }, 100);
};

/**
 * Adjust offshore zoom level based on window width.
 */
const getOffshoreZoom = () => {
  if (window.innerWidth < 600) {
    return 2;
  }
  if (window.innerWidth < 1000) {
    return 3;
  }
  return 4;
};

/**
 * Called by observer.
 * Zoom to center and then animate the line.
 */
const setData = (map, mapboxgl, labels) => async ({
  inland,
  shore,
  offshore,
  travelledDistance,
  isPolarTrajectory,
}) => {
  if (popup) {
    popup.remove();
    popup = null;
  }

  const handleSource = (data, sourceId, speed, options) => {
    return new Promise((resolve) => {
      map
        .once("moveend", () => {
          // Is called after the flyTo is finished.
          draw(0, data, [], map.getSource(sourceId), map, speed, resolve);
        })
        .flyTo({
          center: options.center,
          zoom: options.zoom,
          essential: false, // this animation is considered non-essential with respect to prefers-reduced-motion
        });
    });
  };

  await handleSource(inland, "inland", 0.1, {
    center: inland[0][0],
    zoom: 7,
  });

  await handleSource(shore, "shore", 0.5, {
    zoom: getOffshoreZoom(),
  });
  await handleSource(offshore, "offshore", 0.5, {
    zoom: getOffshoreZoom(),
  });

  publish(trashTrackerIsDoneDrawing);

  const lastOffShoreBulk = offshore[offshore.length - 1];
  const lastBulkItem = lastOffShoreBulk[lastOffShoreBulk.length - 1];
  const popupContent = isPolarTrajectory
    ? `<div class="trash-tracker-location-form__info"><span>${labels.polarPopupText}</span></div>`
    : `<div class="trash-tracker-location-form__info">
        <span class="trash-tracker-location-form__highlight">
          <span class="js-travel-distance">${travelledDistance}</span> KM
        </span>
        <span>${labels.distancePopupText}</span>
      </div>`;

  popup = new mapboxgl.Popup({ closeOnClick: false })
    .setLngLat(lastBulkItem)
    .setHTML(popupContent)
    .addTo(map);
};

const resetData = (map) => () => {
  const features = [
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    },
  ];

  map.getSource("inland").setData({
    type: "FeatureCollection",
    features,
  });
  map.getSource("shore").setData({
    type: "FeatureCollection",
    features,
  });
  map.getSource("offshore").setData({
    type: "FeatureCollection",
    features,
  });

  if (popup) {
    popup.remove();
    popup = null;
  }
};

/**
 * Initialize map.
 * Add default data, layers and handlers.
 */
export const initializeMapForData = ({ map, data = [], mapboxgl, labels }) => {
  map
    .addSource("inland", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: data,
      },
    })
    .addLayer({
      id: "inland",
      type: "line",
      source: "inland",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#92b5da",
        "line-width": 4,
      },
    });

  map
    .addSource("shore", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: data,
      },
    })
    .addLayer({
      id: "shore",
      type: "line",
      source: "shore",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#92b5da",
        "line-width": 4,
        "line-dasharray": [1, 1],
      },
    });

  map
    .addSource("offshore", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: data,
      },
    })
    .addLayer({
      id: "offshore",
      type: "line",
      source: "offshore",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#92b5da",
        "line-width": 4,
      },
    });

  // Observer listeners.
  subscribe(trashTrackerUpdateData, setData(map, mapboxgl, labels));
  subscribe(trashTrackerResetData, resetData(map));
};
