const SITE_BANNER_SELECTOR = ".js-site-banner";

const STORAGE_KEY = "toc_site_banner_closed";

/**
 * Deprecated: this is now solved in site-banner.twig, because showing the
 * banner on pageready caused a large shift in layout. Google penalizes this.
 * See also: https://web.dev/optimize-cls/
 *
 * Don't use this enhancer anymore. I'm leaving it here so this module is
 * "complete", but we're not importing it anymore in main.
 */
export const enhancer = (banner) => {
  if (!Modernizr.sessionstorage || sessionStorage.getItem(STORAGE_KEY)) {
    return;
  }
  banner.setAttribute("aria-hidden", "false");
};

export const handler = (el, e) => {
  sessionStorage.setItem(STORAGE_KEY, "true");
  const bar = document.querySelector(SITE_BANNER_SELECTOR);
  if (!bar) {
    return;
  }
  bar.setAttribute("aria-hidden", "true");
};
