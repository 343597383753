const getAttributes = (el) => {
  return {
    type: el.getAttribute("data-event-type"),
    category: el.getAttribute("data-event-category"),
    action: el.getAttribute("data-event-action"),
    label: el.getAttribute("data-event-label"),
    value: el.getAttribute("data-event-value"),
  };
};

export const pushEvent = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const trackEvent = ({
  type = "event",
  category,
  action,
  label,
  value,
}) => {
  if (!type || !category || !action) {
    console.error("Missing arguments in trackEvent");
    return;
  }
  const data = {
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value,
  };
  pushEvent({ event: type, ...data });
};

export const handler = (el, e) => {
  trackEvent(getAttributes(el));
};

export const enhancer = (el) => {
  if (this.nodeName.toUpperCase() !== "FORM") {
    console.warn("I don't know how to enhance non-forms.");
  }
  this.addEventListener("submit", (e) => {
    trackEvent(getAttributes(el));
  });
};
