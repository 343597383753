import { map } from "./util";
import { matchesBreakpoint } from "./responsive";

const HTML_ACTIVE_CLASS = "has-active-site-nav";

const NAV_SELECTOR = ".js-site-nav";
const TOGGLE_BUTTON_SELECTOR = ".js-site-nav-toggle";
const TOGGLE_BUTTON_LABEL_SELECTOR = ".js-site-nav-toggle-label";
const SUBMENU_TOGGLE_SELECTOR = ".js-submenu-toggle";

const toggleSiteNav = ({ shouldHide }) => {
  const nav = document.querySelector(NAV_SELECTOR);
  const button = document.querySelector(TOGGLE_BUTTON_SELECTOR);
  const label = button.querySelector(TOGGLE_BUTTON_LABEL_SELECTOR);

  nav.setAttribute("data-expanded", !shouldHide);
  button.setAttribute("aria-expanded", !shouldHide);
  label.textContent = shouldHide ? "Menu" : "Close";
  document.documentElement.classList.toggle(HTML_ACTIVE_CLASS, !shouldHide);
};

const SiteNavSubmenus = (nav) => {
  const toggles = nav.querySelectorAll(SUBMENU_TOGGLE_SELECTOR);

  const alignSubmenu = (submenu) => {
    const screenWidth = document.body.offsetWidth;
    if (submenu.getBoundingClientRect().right > screenWidth) {
      submenu.classList.add("is-right-aligned");
    }
  };

  const toggleSubmenu = ({ toggle, shouldShow }) => {
    const submenu = document.getElementById(
      toggle.getAttribute("aria-controls")
    );
    submenu.setAttribute("aria-hidden", !shouldShow);
    toggle.setAttribute("aria-expanded", shouldShow);
    toggle.parentNode.setAttribute("data-expanded", shouldShow);
    if (shouldShow) {
      alignSubmenu(submenu);
    }
  };

  const toggleActiveState = ({ toggle, shouldShow }) => {
    shouldShow =
      typeof shouldShow !== "undefined"
        ? shouldShow
        : toggle.getAttribute("aria-expanded") !== "true";

    // Reset states and set state for active item.
    map(toggles, (t) => toggleSubmenu({ toggle: t, shouldShow: false }));
    toggleSubmenu({ toggle, shouldShow });
  };

  const toggleClickHandler = (toggle) => (e) =>
    toggleActiveState({
      toggle,
      shouldShow: matchesBreakpoint("mediumLarge") ? true : undefined,
    });

  const toggleMouseOverHandler = (toggle) => (e) =>
    toggleActiveState({
      toggle,
      shouldShow: true,
    });

  const toggleMouseLeaveHandler = (toggle) => (e) =>
    toggleActiveState({
      toggle,
      shouldShow: false,
    });

  return {
    init() {
      map(toggles, (toggle) => {
        toggle.addEventListener("click", toggleClickHandler(toggle));
        if (matchesBreakpoint("mediumLarge")) {
          const toggleContainer = toggle.parentNode;
          toggleContainer.addEventListener(
            "mouseover",
            toggleMouseOverHandler(toggle)
          );
          toggleContainer.addEventListener(
            "mouseleave",
            toggleMouseLeaveHandler(toggle)
          );
        }
      });
    },
  };
};

export const toggleHandler = (button, e) => {
  e.preventDefault();
  toggleSiteNav({
    shouldHide: button.getAttribute("aria-expanded") === "true",
  });
};

export const enhancer = (nav) => {
  const submenus = SiteNavSubmenus(nav);
  submenus.init();
};
