const DATA_STORAGE_KEY = "user-location";

const getCountryFromStorage = () => localStorage.getItem(DATA_STORAGE_KEY);
const storeCountry = (code) => {
  localStorage.setItem(DATA_STORAGE_KEY, code);
};

/**
 * The API will return an object with the following keys:
 * - ipAddress
 * - countryCode
 * - reason
 */
const fetchCountry = async (url) => {
  try {
    const options = { method: "POST", mode: "cors" };

    const response = await fetch(url, options);
    const parsedResponse = await response.json();

    return parsedResponse;
  } catch (err) {
    return err;
  }
};

/**
 * Get the user location from local storage or the micro-service.
 */
export default async (url) => {
  if (!getCountryFromStorage()) {
    const countryCode = await fetchCountry(url);

    if (countryCode) {
      storeCountry(countryCode.countryCode);
    }
  }

  return getCountryFromStorage();
};
