import { publish, subscribe } from "../observer";
import {
  hideOverlays,
  showInfoBox,
  hideInfoBox,
  updateCitizenScienceData,
  updateMarkerInfo,
  updateClusterInfo,
  citizenScienceSideBarShowSingle,
  citizenScienceSideBarShowOverview,
} from "../observer-subjects";
import { addActiveMarker, removeActiveMarker } from "./active-marker";

const state = {
  activeId: null,
};
let globalMap = null;

const updateActiveId = (id) => {
  state.activeId = id;
};

const clusterConfig = (source) => ({
  id: "clusters",
  type: "circle",
  source,
  filter: ["has", "point_count"],
  paint: {
    "circle-color": "#ffffff",
    "circle-radius": ["step", ["get", "point_count"], 18, 10, 25, 130, 36],
  },
});

const clusterCount = (source) => ({
  id: "cluster-count",
  type: "symbol",
  source,
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["Roboto Mono Regular"],
    "text-size": 14,
  },
  paint: {
    "text-color": "#2b6aac",
  },
});

const marker = (source) => ({
  id: "marker",
  type: "circle",
  source,
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": [
      "case",
      ["boolean", ["feature-state", "active"], false],
      "#FD4141",
      "#92b5da",
    ],
    "circle-radius": 9,
    "circle-stroke-width": 2,
    "circle-stroke-color": "#ffffff",
    "circle-opacity": 1,
    "circle-stroke-opacity": 1,
  },
});

const showClusterOverview = (clusterSource, clusterId, pointCount) => {
  clusterSource.getClusterLeaves(
    clusterId,
    pointCount,
    0,
    (err, clusterPoints) => {
      if (err) return;
      publish(updateClusterInfo, clusterPoints);
      publish(showInfoBox);
      publish(citizenScienceSideBarShowOverview);
    }
  );
};

const handleClusterClick = (map, source, mapboxgl) => (event) => {
  const features = map.queryRenderedFeatures(event.point, {
    layers: ["clusters"],
  });
  const {
    cluster_id: clusterId,
    point_count: pointCount,
  } = features[0].properties;

  const clusterSource = map.getSource(source);
  const beginZoomLevel = map.getZoom();

  clusterSource.getClusterExpansionZoom(clusterId, function (
    err,
    newZoomLevel
  ) {
    if (err) return;

    map
      .easeTo({
        center: features[0].geometry.coordinates,
        zoom: newZoomLevel,
      })
      .once("zoomend", (e) => {
        // Only execute if you click on a cluster and only execute once!

        // Bail out if the zoom level is to small (is too zoomed out)
        if (newZoomLevel < 13) {
          return;
        }

        // Only set a timeout if the map is zoomed out at at least zoom level 7.
        const timeOut = beginZoomLevel < 7 ? 500 : 0;

        // This timeout is needed, otherwise the active marker is misplaced.
        setTimeout(() => {
          // Get newFeatures.
          // Sometimes there are more features in this query.
          // Match the featureId with the original featureId to insure the same feature is chosen.
          const newFeatures = map
            .queryRenderedFeatures(e.point, {
              layers: ["clusters"],
            })
            .find((item) => item.id === features[0].id);

          // Use old features if no new features are found.
          const useFeatures =
            newFeatures && newFeatures.length ? newFeatures : features;

          // Update active marker.
          removeActiveMarker();
          addActiveMarker(mapboxgl, map, useFeatures[0].geometry.coordinates);

          // Show cluster-overview.
          showClusterOverview(clusterSource, clusterId, pointCount);
        }, timeOut);
      });
  });
};

const handleClusterMouseEnter = (map) => () => {
  map.getCanvas().style.cursor = "pointer";
};
const handleClusterMouseLeave = (map) => () => {
  map.getCanvas().style.cursor = "";
};

/**
 * Handles a click on a specific marker.
 */
const handleMarkerClick = (map, source, mapboxgl) => (event) => {
  const { id } = event.features[0].properties;

  // Remove active state from current active marker.
  if (state.activeId) {
    map.setFeatureState({ source, id: state.activeId }, { active: false });
  }

  // Activate clicked marker.
  map.setFeatureState({ source, id }, { active: true });
  updateActiveId(id);
  publish(updateMarkerInfo, {
    ...event.features[0].properties,
    id,
    hideBackButton: true,
  });
  publish(showInfoBox);
  publish(citizenScienceSideBarShowSingle);

  // Add active marker to the map.
  removeActiveMarker();
  addActiveMarker(mapboxgl, map, event.features[0].geometry.coordinates);
};

/**
 * Called by Observer.
 * Reset state of active marker.
 */
const handleInfoBoxClose = (map, source) => () => {
  removeActiveMarker();

  if (state.activeId) {
    map.setFeatureState({ source, id: state.activeId }, { active: false });
    updateActiveId(null);
  }
};

/**
 * Set new data to the map.
 */
export const setData = (map, source) => (data) => {
  map.getSource(source).setData({
    type: "FeatureCollection",
    features: data,
  });
};

/**
 * Create markers based on given data and append to the map.
 */
export const initializeMapForData = ({ map, data, source, mapboxgl }) => {
  globalMap = map;

  const defaultSource = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: data,
    },
    cluster: true,
    clusterMaxZoom: 22,
    clusterRadius: 50,
  };

  map
    .addSource(source, defaultSource)
    .addLayer(clusterConfig(source))
    .addLayer(clusterCount(source))
    .addLayer(marker(source))
    .on("click", "marker", handleMarkerClick(map, source, mapboxgl))
    .on("click", "clusters", handleClusterClick(map, source, mapboxgl))
    .on("mouseenter", "clusters", handleClusterMouseEnter(map))
    .on("mouseleave", "clusters", handleClusterMouseLeave(map));

  // Observer listeners.
  subscribe(hideInfoBox, handleInfoBoxClose(map, source));
  subscribe(updateCitizenScienceData, setData(map, source));
};

/**
 * Called by hansel.
 * Fly to specific survey.
 */
export const handler = (element, event) => {
  event.preventDefault();
  const coodinates = element.getAttribute("data-lat-long").split(",").reverse();

  globalMap.flyTo({
    center: coodinates,
    zoom: 20,
    speed: 2,
  });

  publish(hideOverlays);
};
