import { publish, subscribe } from "./observer";
import { closePopup, openPopup, forceClosePopup } from "./observer-subjects";

/**
 * Force close popup when the escape key is pressed and remove all relevant
 * event listeners.
 */
const forceClose = (event) => {
  if (event.keyCode === 27) {
    publish(forceClosePopup);
    window.removeEventListener("keydown", forceClose);
  }
};

/**
 * Update state of popup.
 * Each time the popup is imported in the template a separate instance of this
 * enhancer is called. Therefore on a popup observer message, each individual
 * popup will handle it's own state.
 */
const updatePopupState = (element, bodyElement, { open }) => (id) => {
  const elementPopupId = element.getAttribute("data-popup-id");

  // Update visibility of individual popup element.
  if (elementPopupId === id) {
    element.setAttribute("data-visible", open);
    element.setAttribute("aria-hidden", !open);
  } else {
    element.setAttribute("data-visible", false);
    element.setAttribute("aria-hidden", true);
  }

  // Disable scrolling of the page if the popup is open.
  bodyElement.setAttribute("popup-is-open", open);
};

/**
 * Close popup even if the caller doesn't know the popup id.
 */
const handleForceClosePopup = (element) => () => {
  element.setAttribute("data-visible", "false");
  element.setAttribute("aria-hidden", "true");
};

/**
 * Add subscriptions.
 */
export const enhancer = (element) => {
  const bodyElement = document.querySelector("body");

  subscribe(openPopup, updatePopupState(element, bodyElement, { open: true }));
  subscribe(
    closePopup,
    updatePopupState(element, bodyElement, { open: false })
  );

  subscribe(forceClosePopup, handleForceClosePopup(element));
};

/**
 * Handler to open the popup.
 */
export const openPopupHandler = (element, event) => {
  publish(openPopup, element.getAttribute("data-popup-id"));
  window.addEventListener("keydown", forceClose);
};

/**
 * Handler to close the popup.
 */
export const closePopupHandler = (element, event) => {
  publish(closePopup, element.getAttribute("data-popup-id"));
  window.removeEventListener("keydown", forceClose);
};
