import { isSafari } from "./util";

const LOCAL_STORAGE_KEY = "TOCUpdatePushNotificationBannerDateTimeStamp";
// Calculate a month in milliseconds by taking the milliseconds of 1 day * 30.
const MAX_TIME_BETWEEN_NOTIFICATIONS_IN_MILLISECONDS = 86400000 * 30;

/**
 * Creates a dateStamp in milliseconds.
 * @returns {Number}
 */
const getCurrentDateTimeStamp = () => {
  const date = new Date();
  return date.getTime();
};

/**
 * Executed when Notification access is granted.
 * @param {HTMLElement} element
 */
const hideBanner = (element) => {
  element.setAttribute("aria-hidden", "true");
};

/**
 * Executed when PushAlert lib is initialized.
 * @param {HTMLElement} element
 */
const showBanner = (element) => {
  element.removeAttribute("aria-hidden");
};

/**
 * Check if the user already answered the native push-message permission request.
 * @returns {Boolean}
 */
const permissionIsAlreadySet = () => {
  if (!Notification) {
    return false;
  }
  return Notification.permission !== "default";
};

/**
 * Check if the user already set a preference to hide the banner.
 * If so, check if the time between the action and now is more than the setting.
 * @returns {Boolean}
 */
const isBannerPreferenceAlreadySetAndStillValid = () => {
  if (!window.localStorage) {
    return false;
  }

  const storedDateTimeStamp = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!storedDateTimeStamp) {
    return false;
  }

  const currentDateTimeStamp = getCurrentDateTimeStamp();
  const timeDifferenceInMilliseconds =
    currentDateTimeStamp - storedDateTimeStamp;

  return (
    timeDifferenceInMilliseconds <
    MAX_TIME_BETWEEN_NOTIFICATIONS_IN_MILLISECONDS
  );
};

/**
 * Add custom listeners to the PushAlert library.
 * @param {HTMLElement} element
 */
const addPushAlertListeners = (element) => {
  window.pushalertbyiw = [
    ...(window.pushalertbyiw || []),
    ["onReady", () => showBanner(element)],
    ["onSuccess", () => hideBanner(element)],
    ["onFailure", () => hideBanner(element)],
  ];
};

/**
 * Initialize function, called on load.
 * @param {HTMLElement} element
 */
export const enhancer = (element) => {
  const supportsPushNotificationApi = "PushManager" in window;

  // Push notifications on Safari need a certificate that's not yet uploaded.
  if (
    !supportsPushNotificationApi ||
    isSafari() ||
    permissionIsAlreadySet() ||
    isBannerPreferenceAlreadySetAndStillValid()
  ) {
    return;
  }

  addPushAlertListeners(element);
};

/**
 * Handle click on banner.
 * @param {HTMLButtonElement} element
 * @param {ClickEvent} event
 */
export const askForNotificationPromptHandler = (element, event) => {
  window.PushAlertCo.forceSubscribe();
};

/**
 * Handle click on remove-banner button.
 * Adds current dateTime stamp to local-storage.
 * @param {HTMLButtonElement} element
 * @param {ClickEvent} event
 */
export const removeBannerHandler = (element, event) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, getCurrentDateTimeStamp());

  const targetElementSelector = element.getAttribute("data-target");
  const targetElement = document.querySelector(targetElementSelector);
  hideBanner(targetElement);
};
