import { htmlToElement, parseJson, preventingDefault } from "@grrr/utils";

const RECAPTCHA_FIELD_NAME = "g-recaptcha-response";
const SALESFORCE_FIELDS_ATTRIBUTE = "data-salesforce-fields";
const SALESFORCE_MESSAGE_FIELDS_ATTRIBUTE = "data-salesforce-message-fields";
const OVERWRITE_FIELD_ATTRIBUTE = "data-overwrite-field";
const ALERT_SELECTOR = ".js-alert";
const BUTTON_SELECTOR = ".js-button";

const RECAPTCHA_ALERT = `Please make sure to check the <strong>reCAPTCHA checkbox</strong> above.`;
const FORM_ALERT = `Please make sure to fill in all <strong>required</strong> fields (marked with <strong>*</strong>).`;

/**
 * Normalize fields before submit to Salesforce.
 *
 * - Certain fields are native Salesforce fields and should be submitted as such.
 * - Certain fields only exist in our template, and will be appended to the message field.
 * - Certain Salesforce fields are appended to the message field as well.
 */
const ContactFormSubmit = (form) => {
  const alertEl = form.querySelector(ALERT_SELECTOR);
  const buttonEl = form.querySelector(BUTTON_SELECTOR);

  /**
   * All configured fields (as `reference: input name` object).
   */
  const salesforceFields = parseJson(
    form.getAttribute(SALESFORCE_FIELDS_ATTRIBUTE)
  );

  /**
   * All Salesforce fields that should be appended to the message too
   * (array with input names).
   */
  const salesforceMessageFields = parseJson(
    form.getAttribute(SALESFORCE_MESSAGE_FIELDS_ATTRIBUTE)
  );

  /**
   * All allowed fields (array with input names only).
   *
   * - All regular Salesforce fields.
   * - All hidden Salesforce fields.
   * - The ReCAPTCHA response.
   */
  const allowedFields = [
    ...Object.values(salesforceFields),
    ...[...form.querySelectorAll('input[type="hidden"]')].map(
      (input) => input.name
    ),
    RECAPTCHA_FIELD_NAME,
  ];

  /**
   * All fields that need to overwrite other fields on submit.
   */
  const overwriteFields = [
    ...form.querySelectorAll(`[${OVERWRITE_FIELD_ATTRIBUTE}]`),
  ];

  /**
   * Create a `label: value` string.
   */
  const createLabelValueString = (name, value) => {
    const input = form.querySelector(`[name="${name}"]`);
    const label = form.querySelector(`[for="${input.id}"]`);
    if (!input || !label) {
      return "";
    }
    return `${label.textContent}: ${value}`;
  };

  /**
   * Create message template for appending fields to it (incl. original message).
   */
  const createMessageTemplate = (message) => `${message}\r\n\r\n—————\r\n`;

  /**
   * Create updated message with optional fields appended to it.
   */
  const createMessage = ({ message, fieldData }) => {
    return Object.entries(fieldData).reduce(
      (acc, [name, value]) => {
        return `${acc}\r\n${createLabelValueString(name, value)}`;
      },
      Object.keys(fieldData).length ? createMessageTemplate(message) : message
    );
  };

  /**
   * Check if value matches ISO8601 date format (`YYYY-MM-DD`), coming from
   * our native date inputs.
   */
  const isPossibleIsoDate = (value) => value.match(/^\d{4}-\d{2}-\d{2}$/);

  /**
   * Basic ISO8601 date conversion to `DD/MM/YYYY` format.
   */
  const convertPossibleIsoDate = (value) => {
    const day = value.substring(8, 10);
    const month = value.substring(5, 7);
    const year = value.substring(0, 4);
    return `${day}/${month}/${year}`;
  };

  /**
   * Create a regular `input` or a `textarea`, the latter to preserve line breaks
   * when submitting to Salesforce.
   */
  const createInput = (name) => {
    const input = form.querySelector(`[name="${name}"]`);
    const isTextarea = input.tagName.toLowerCase() === "textarea";
    return isTextarea
      ? htmlToElement(
          `<textarea name="${name}" aria-hidden="true" style="display: none;"></textarea>`
        )
      : htmlToElement(`<input type="hidden" name="${name}"/>`);
  };

  /**
   * Create a temporary form and submit the modified FormData to Salesforce.
   * Note: we set the input value via the JS object, else we would have to escape
   * values (e.g. any fields with double quotes, like `captcha_settings`).
   */
  const formSubmit = ({ endpoint, formData }) => {
    const temporaryForm = htmlToElement(`
      <form method="post" action="${endpoint}"></form>
    `);
    formData.forEach((value, name) => {
      const input = createInput(name);
      input.value = value;
      temporaryForm.appendChild(input);
    });
    document.body.appendChild(temporaryForm);
    temporaryForm.submit();
  };

  /**
   * Handle form submits. This is only triggered when the form is valid.
   */
  const submitHandler = (e) => {
    const formData = new FormData(form);

    // Convert possible ISO8601 dates to `DD/MM/YYYY` format.
    formData.forEach((value, name) => {
      if (isPossibleIsoDate(value)) {
        formData.set(name, convertPossibleIsoDate(value));
      }
    });

    // Store disallowed field names.
    const disallowedFields = [...formData.keys()].filter(
      (name) => !allowedFields.includes(name)
    );

    // Overwrite fields, but only if source field is present in the FormData.
    // The source field is removed from FormData after the target is overwritten.
    overwriteFields.forEach((field) => {
      if (formData.has(field.name)) {
        const overwriteName = field.getAttribute(OVERWRITE_FIELD_ATTRIBUTE);
        formData.set(overwriteName, formData.get(field.name));
        formData.delete(field.name);
      }
    });

    // Store all fields and values which need to be appended to the message.
    const messageFieldData = [...formData.entries()].reduce(
      (acc, [name, value]) => {
        return [...disallowedFields, ...salesforceMessageFields].includes(name)
          ? { ...acc, [name]: value }
          : acc;
      },
      {}
    );

    // Remove disallowed fields from FormData.
    disallowedFields.forEach((name) => formData.delete(name));

    // Update the message with all eligible fields appended to it.
    formData.set(
      salesforceFields.message,
      createMessage({
        message: formData.get(salesforceFields.message),
        fieldData: messageFieldData,
      })
    );

    // Create a temporary form and submit it. We do this since we want the user
    // to be redirected to Salesforce natively, and let Salesforce handle the
    // form submit in the browser.
    //
    // Furthermore, CORS headers are not properly set on their side currently, so we
    // can't sumbit it via `fetch` anyway. Plus their responses are usually `200 OK`
    // for everything, so we avoid to having to deal with anything from their side.
    formSubmit({ endpoint: form.action, formData });
  };

  /**
   * Check if the whole form is invalid.
   */
  const isFormValid = () => form.checkValidity && form.checkValidity();

  /**
   * Handle form input changes, triggered after a first invalid submit.
   */
  const formChangeHandler = () => {
    if (!isFormValid()) {
      return;
    }
    alertEl.setAttribute("aria-hidden", "true");
    form.removeEventListener("keyup", formChangeHandler);
    form.removeEventListener("change", formChangeHandler);
  };

  /**
   * Handle button 'clicks'. This is triggered when the form is being submitted
   * regardless of validity (also triggered when hitting `return`);
   */
  const buttonHandler = (e) => {
    const formData = new FormData(form);

    // Show a basic warning when the form is invalid. Note that we're not preventing
    // the default behaviour (`preventDefault`), since we still want native validation.
    if (!isFormValid()) {
      alertEl.innerHTML = FORM_ALERT;
      alertEl.setAttribute("aria-hidden", "false");
      form.addEventListener("keyup", formChangeHandler);
      form.addEventListener("change", formChangeHandler);
      return;
    }

    // Show a basic warning when reCAPTCHA is not checked. This will prevent a
    // pretty sad failed experience when submitting to Salesforce.
    if (!formData.get(RECAPTCHA_FIELD_NAME)) {
      alertEl.innerHTML = RECAPTCHA_ALERT;
      alertEl.setAttribute("aria-hidden", "false");
      e.preventDefault();
    }
  };

  return {
    init() {
      form.addEventListener("submit", preventingDefault(submitHandler));
      buttonEl.addEventListener("click", buttonHandler);
    },
  };
};

export const enhancer = (form) => {
  const contactFormSubmit = ContactFormSubmit(form);
  contactFormSubmit.init();
};
