import { matchesBreakpoint } from "./responsive";

export const SOURCE_NAME = "systems";
export const MARKER_LAYER = "markers";
export const CLUSTER_LAYER = "clusters";

export const MapboxData = ({ container, mapboxgl, token }) => {
  const riverSystemFeatures = window.RIVER_SYSTEM_FEATURES;
  const oceanSystemFeatures = window.OCEAN_SYSTEM_FEATURES;
  const systemFeatures = [...riverSystemFeatures, ...oceanSystemFeatures];

  /**
   * Set access token.
   */
  mapboxgl.accessToken = token;

  /**
   * Initialize map.
   */
  const map = new mapboxgl.Map({
    container: "dashboard-map",
    style: "mapbox://styles/theoceancleanup/ckbqn7x1y5fxh1ilmj6pzk52u",
    center: [95, 5],
    zoom: 3,
    interactive: true,
    scrollZoom: true,
    doubleClickZoom: true,
    attributionControl: false,
    logoPosition: "bottom-left",
  });

  /**
   * Attach controls to the Mapbox map.
   */
  const attachControls = () => {
    const controls = [
      new mapboxgl.AttributionControl({
        compact: false,
      }),
    ];
    if (matchesBreakpoint("small")) {
      controls.push(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: true,
        })
      );
    }
    controls.forEach((control) => map.addControl(control));
  };

  /**
   * Add the GeoJSON source.
   */
  const addSources = (sourceName) => {
    map.addSource(sourceName, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: systemFeatures,
      },
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });
  };

  /**
   * Add the trajectory(paths) of the ocean systems.
   */
  const addTrajectories = () => {
    oceanSystemFeatures
      .map((feature) =>
        // Format lat-long.
        feature.trajectory.map((single) => [single.longitude, single.latitude])
      )
      .forEach((trajectory, index) => {
        // Add source.
        map.addSource(`trajectory-${index}`, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: trajectory,
            },
          },
        });
      });
  };

  /**
   * Add all layers (markers and clusters).
   */
  const addLayers = (sourceName) => {
    map.addLayer({
      id: CLUSTER_LAYER,
      type: "circle",
      source: sourceName,
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#ffffff",
          100,
          "#f1f075",
          750,
          "#f28cb1",
        ],
        "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      },
    });

    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: sourceName,
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["Roboto Mono Regular"],
        "text-size": 14,
      },
    });

    map.addLayer({
      id: MARKER_LAYER,
      type: "circle",
      source: sourceName,
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": [
          "case",
          ["==", "active", ["string", ["get", "status"], ""]],
          "#92b5da",
          "rgba(255, 255, 255, 0.25)",
        ],
        "circle-radius": 9,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#ffffff",
        "circle-opacity": [
          "case",
          ["boolean", ["feature-state", "active"], false],
          0,
          1,
        ],
        "circle-stroke-opacity": [
          "case",
          ["boolean", ["feature-state", "active"], false],
          0,
          1,
        ],
      },
    });

    oceanSystemFeatures.forEach((feature, index) => {
      map.addLayer({
        id: `trajectory-${index}`,
        type: "line",
        source: `trajectory-${index}`,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#92b5da",
          "line-width": 4,
          "line-dasharray": [2, 1.2],
        },
      });
    });
  };

  return {
    init() {
      return new Promise((resolve, reject) => {
        attachControls();
        map.on("load", (e) => {
          addSources(SOURCE_NAME);
          addTrajectories();
          addLayers(SOURCE_NAME);
        });
        resolve({ map, sourceName: SOURCE_NAME });
      });
    },
  };
};
