/* eslint-disable wrap-iife */

import { throttle, uuid } from "@grrr/utils";
import { onDocumentLoaded } from "./ready";
import { register as scrollListener, isInView } from "./scroll-listener";
import { getDocWidth } from "./responsive";
import { prefersNoMotion, prefersToSaveData, isSafari } from "./util";

const CONTROLS_ATTRIBUTE = "data-controls-allowed";

/**
 * Lazyload autoplay video in the appropriate size and format.
 * The video is paused when scrolled out of view, and is not autoplayed
 * if the user prefers not to.
 */

const getVideoWidth = () => {
  const docWidth = getDocWidth();
  switch (true) {
    case docWidth <= 480:
      return "270p";
    case docWidth <= 1400:
      return "720p";
    default:
      return "1080p";
  }
};

const getVideoType = ({ video, hasWebM }) => {
  if (
    hasWebM &&
    video.canPlayType &&
    video.canPlayType("video/webm; codecs=vp9")
  ) {
    return "webm";
  }
  return "mp4";
};

const setAndPlayVideo = ({ container, autoplay = true }) => {
  const video = document.createElement("video");
  const size = getVideoWidth();
  const type = getVideoType({
    video,
    hasWebM: container.getAttribute("data-has-webm") === "true",
  });

  video.setAttribute("role", "presentation");
  video.setAttribute("aria-hidden", "true");
  video.setAttribute("hidden", "");
  video.setAttribute("preload", "auto");
  video.setAttribute("loop", "");
  video.setAttribute("muted", "");
  video.setAttribute("playsinline", "");
  video.setAttribute("webkit-playsinline", "");
  video.setAttribute("poster", container.getAttribute("data-poster"));
  video.setAttribute("src", container.getAttribute(`data-${type}-${size}`));
  video.muted = true;

  // Append the video.
  container.appendChild(video);

  // Do not autoplay video on browsers that want to save data or prefer
  // reduced motion. Add manual controls to start it.
  if (!autoplay) {
    video.setAttribute("controls", "");
    video.removeAttribute("hidden");
    video.removeAttribute("aria-hidden");
    container.classList.add("has-controls");
    return;
  }

  const playVideo = () => {
    video.play();
    // Prevent some Safari black flashes when playing video.
    if (isSafari()) {
      setTimeout(() => {
        video.removeAttribute("hidden");
      }, 100);
    } else {
      video.removeAttribute("hidden");
    }
    video.removeEventListener("canplaythrough", playVideo, false);
  };

  // Play or load the video if it's in view.
  if (isInView(video)) {
    if (video.readyState > 3) {
      playVideo();
    } else {
      video.addEventListener("canplaythrough", playVideo, false);
      video.setAttribute("autoplay", "");
      video.load();
    }
  }

  // Play or pause the video when eligble.
  const scrollHandler = (e) => {
    const visible = isInView(video);
    if (visible && video.paused) {
      playVideo();
    } else if (!visible && !video.paused) {
      video.pause();
    }
  };

  // Attach scroll listener if it should autoplay.
  if (autoplay) {
    onDocumentLoaded(() => {
      scrollListener(`autoplay-video-${uuid}`, throttle(scrollHandler, 100));
    });
  }
};

export const enhancer = (container) => {
  // When showing manual controls is impossble, do not add the video when
  // reduced motion or data saving is preferred.
  const noControlsAllowed =
    container.getAttribute(CONTROLS_ATTRIBUTE) === "false";
  if ((prefersNoMotion || prefersToSaveData) && noControlsAllowed) {
    return;
  }

  // Add the video.
  setAndPlayVideo({
    container,
    autoplay: !prefersNoMotion && !prefersToSaveData,
  });
};
