/* eslint-disable no-unused-vars */

import Flickity from "flickity";
import { debounce } from "@grrr/utils";
import { getDocWidth } from "./responsive";

const SLIDER_SELECTOR = ".js-slider";

const resetSliderBounds = (slider) => {
  slider.style.width = ``;
  slider.style.marginLeft = ``;
  slider.style.paddingLeft = ``;
};

/**
 * Update bounds because of slider offset.
 * It's glued to the right side of the screen, with a lef margin, but needs to
 * have arrows glued to the edges of the screen.
 */
const updateSliderBounds = (slider) => {
  const bounds = slider.getBoundingClientRect();
  const rightOffset = getDocWidth() - bounds.right;
  slider.style.width = `${bounds.width + rightOffset}px`;

  slider.style.width = `${getDocWidth()}px`;
  slider.style.marginLeft = `-${rightOffset}px`;
  slider.style.paddingLeft = `${rightOffset}px`;
};

export const enhancer = (container) => {
  const slider = container.querySelector(SLIDER_SELECTOR);

  updateSliderBounds(slider);

  const flkty = new Flickity(slider, {
    autoPlay: false,
    cellAlign: "left",
    contain: true,
    freeScroll: false,
    pageDots: false,
    prevNextButtons: true,
    arrowShape: "",
    accessibility: false,
  });

  // Quick and dirty fix to disable the 'next' button when the last slide is
  // visible. Due to accessibility the slider allows to go to the last slide,
  // while that is already visible for a while on desktop screens.
  // All slides are focusable so we disable keyboard controls for the slider itself.
  flkty.on("scroll", (e) => {
    const next = container.querySelector(".flickity-prev-next-button.next");
    const last = flkty.getLastCell();
    if (last.element.getBoundingClientRect().right < getDocWidth()) {
      next.disabled = true;
    } else {
      next.disabled = false;
    }
  });

  window.addEventListener(
    "resize",
    debounce(() => {
      resetSliderBounds(slider);
      updateSliderBounds(slider);
      flkty.resize();
    }, 500)
  );
};
