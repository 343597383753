import { prop } from "@grrr/utils";

const NAME = "Donation";
const BRAND = "";

const getDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
};

export const checkoutOptions = (state) =>
  new Promise((resolve, reject) => {
    // When Tag Manager is blocked the callback will fail
    if (typeof window.google_tag_manager === "undefined") {
      resolve(`'Google Tag Manager is not defined, but that's fine.`);
    }

    if (state.country && state.currency === "EUR") {
      // Enhanced Ecommerce — checkout options (step 1, country, EUR only)
      getDataLayer().push({
        event: "checkoutOption",
        ecommerce: {
          checkout_option: {
            actionField: {
              step: 1,
              option: state.country,
            },
          },
        },
      });
    }

    // Enhanced Ecommerce — checkout options (step 2, payment method)
    getDataLayer().push({
      event: "checkoutOption",
      ecommerce: {
        checkout_option: {
          actionField: {
            step: 2,
            option: state.paymentmethod || "paypal",
          },
        },
      },
      eventTimeout: 2000,
      eventCallback: (e) =>
        resolve("The Google Tag Manager eventCallback was called."),
    });
  });

export const checkout = (state) => {
  const products = [
    {
      id: "1",
      name: NAME,
      price: state.amount_custom ? state.amount_custom : state.amount,
      category: state.frequency,
      quantity: 1,
      brand: BRAND,
    },
  ];
  // Enhanced Ecommerce — checkout step 1
  getDataLayer().push({
    pageVirtual: "/donate/details/",
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 1,
        },
        products,
      },
    },
  });
  // Enhanced Ecommerce — checkout step 2
  getDataLayer().push({
    pageVirtual: "/donate/payment/",
    event: "checkout",
    ecommerce: {
      actionField: {
        step: 2,
      },
      products,
    },
  });
};

export const addToCart = (state) => {
  // Enhanced Ecommerce — addToCart
  getDataLayer().push({
    event: "addToCart",
    ecommerce: {
      currencyCode: state.currency,
      add: {
        products: [
          {
            id: "1",
            name: NAME,
            price: state.amount_custom ? state.amount_custom : state.amount,
            category: state.frequency,
            quantity: 1,
            brand: BRAND,
          },
        ],
      },
    },
  });
};

export const purchase = (data) => {
  // Enhanced Ecommerce — purchase
  getDataLayer().push({
    event: "purchase",
    ecommerce: {
      purchase: {
        actionField: {
          id: prop("id", data),
          affiliation: BRAND,
          revenue: prop("amount", data),
          tax: "0.00",
          shipping: "0.00",
        },
        products: [
          {
            name: NAME,
            id: "1",
            price: prop("amount", data),
            category: prop("frequency", data),
            quantity: 1,
            brand: BRAND,
          },
        ],
      },
    },
    transactionPaymentMethod: prop("paymentMethod", data),
  });
};
