import Flickity from "flickity-imagesloaded";
import { forEach } from "./util";

/**
 * Clone caption to an easier stylable location
 */
const addClonedCaption = (el, flkty) => {
  const slideFooter = el.querySelector(".js-footer");
  const captionPlaceholder = document.createElement("span");
  captionPlaceholder.classList.add("media-slider__caption");
  captionPlaceholder.setAttribute("aria-hidden", "true");

  flkty.on("cellSelect", () => {
    const selectedCaption = el.querySelector(".js-item.is-selected figcaption")
      .innerHTML;
    // Only append caption if caption has text (and not only spaces)
    if (/\S/.test(selectedCaption)) {
      captionPlaceholder.innerHTML = selectedCaption;
      slideFooter.appendChild(captionPlaceholder);
    }
  });
};

/**
 * Slide indicator, displaying 1/total slides
 */
const addSlideIndicator = (el, flkty) => {
  const indicator = el.querySelector(".js-indicator");
  if (!indicator) {
    return;
  }
  const slideTotal = flkty.cells.length;

  flkty.on("cellSelect", () => {
    const friendlyCellIndex = flkty.selectedIndex + 1;
    indicator.innerHTML = `${friendlyCellIndex}/${slideTotal}`;
  });
};

/**
 * Toggle ARIA active descendant attribute
 */
const setAriaActiveDescendant = (el, flkty) => {
  const randomId = el.getAttribute("data-random-id");
  const id = `figure-${randomId}-${flkty.selectedIndex + 1}`;
  el.setAttribute("aria-activedescendant", id);
};

/**
 * Toggle ARIA-hidden attributes
 */
const setAriaAttributes = (el, flkty) => {
  const slides = el.querySelectorAll(".js-item");

  flkty.on("cellSelect", () => {
    setAriaActiveDescendant(el, flkty);
    forEach(slides, (slide, i) => {
      const show = slide.classList.contains("is-selected");
      const anchor = slide.querySelector("a");
      slide.querySelector("figcaption").setAttribute("aria-hidden", !show);
      if (anchor) {
        anchor.setAttribute("tabindex", show ? "" : "-1");
      }
    });
  });

  setAriaActiveDescendant(el, flkty);
};

export const enhancer = (el) => {
  const slider = el.querySelector(".js-slider");
  const ar = el.getAttribute("data-aspect-ratio");

  const flkty = new Flickity(slider, {
    autoPlay: false,
    cellAlign: "left",
    contain: true,
    imagesLoaded: true,
    adaptiveHeight: ar === "unconstrained",
    cellSelector: "figure",
    pageDots: false,
    prevNextButtons: true,
    arrowShape: {
      x0: 10,
      x1: 60,
      y1: 50,
      x2: 70,
      y2: 40,
      x3: 30,
    },
  });

  addClonedCaption(el, flkty);
  addSlideIndicator(el, flkty);
  setAriaAttributes(el, flkty);
};
