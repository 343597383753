// import { memoize } from '@grrr/utils';
import { getDocHeight } from "./responsive";
import { register as scrollListener } from "./scroll-listener";

const TRIGGER_ATTRIBUTE = "data-animate-from";
const TRIGGERED_ATTRIBUTE = "data-triggered";

const MapScroller = (container) => {
  const items = [];

  const getTop = (el) => el.getBoundingClientRect().top;
  const getHeight = (el) => el.getBoundingClientRect().height;

  const getPercentage = (el) => {
    return ((getTop(el) * 100) / (getHeight(el) - getDocHeight())) * -1;
  };

  const scrollHandler = (e) => {
    const percentage = getPercentage(container);
    items.forEach((item) => {
      item.el.setAttribute(TRIGGERED_ATTRIBUTE, percentage > item.percentage);
    });
  };

  const setItems = () => {
    const itemElements = container.querySelectorAll(`[${TRIGGER_ATTRIBUTE}]`);
    [...itemElements].forEach((el) => {
      items.push({
        el,
        percentage: parseFloat(el.getAttribute(TRIGGER_ATTRIBUTE), 10),
      });
    });
  };

  return {
    init() {
      setItems();
      scrollListener("cleanup-map-scroller", scrollHandler);
    },
  };
};

export const enhancer = (container) => {
  const scroller = MapScroller(container);
  scroller.init();
};
