import CountdownClock from "./countdown-clock";

const MONO_FONT =
  "https://fonts.googleapis.com/css?family=Roboto+Mono:300&display=swap";

const CLOCK_SELECTOR = ".js-clock";
const START_DATE_ATTRIBUTE = "data-start-date";
const END_DATE_ATTRIBUTE = "data-end-date";

const LiveEventBanner = (container, dates) => {
  const isAfterDate = (now, date) => now >= date;
  const isBeforeDate = (now, date) => now <= date;
  const isInBetweenDates = (now, start, end) => now >= start && now <= end;

  const getState = () => {
    if (isInBetweenDates(dates.now, dates.start, dates.end)) {
      return "during";
    }
    if (isBeforeDate(dates.now, dates.start)) {
      return "before";
    }
    if (isAfterDate(dates.now, dates.end)) {
      return "after";
    }
    return "";
  };

  return {
    init() {
      const state = getState(dates);

      // Set type and unhide all elemts for that type.
      container.setAttribute("data-state", state);
      [...container.querySelectorAll(`[data-state="${state}"]`)].forEach(
        (el) => {
          el.removeAttribute("aria-hidden");
        }
      );

      if (state === "before") {
        // Append Roboto Mono stylesheet, since it's only used in this state.
        const font = document.createElement("link");
        font.setAttribute("rel", "stylesheet");
        font.setAttribute("href", MONO_FONT);
        document.head.appendChild(font);

        // Start the clock and show it.
        const clockEl = container.querySelector(CLOCK_SELECTOR);
        const countdownClock = CountdownClock(clockEl, dates.start);
        countdownClock.init();
        clockEl.removeAttribute("aria-hidden");
      }

      // Show the banner.
      container.removeAttribute("aria-hidden");
    },
  };
};

export const enhancer = (banner) => {
  const startDateString = banner.getAttribute(START_DATE_ATTRIBUTE);
  const endDateString = banner.getAttribute(END_DATE_ATTRIBUTE);
  if (!startDateString || !endDateString) {
    return;
  }

  const dates = {
    start: Date.parse(`${startDateString}`),
    end: Date.parse(`${endDateString}`),
    now: Date.now(),
  };

  const liveBanner = LiveEventBanner(banner, dates);
  liveBanner.init();
};
