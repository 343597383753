import { htmlToElement, memoize, preventingDefault } from "@grrr/utils";
import { getIntegerLabel, getRelativeTimeLabel, waitForGlobal } from "./util";
import { getDocWidth, matchesBreakpoint } from "./responsive";
import { publish } from "./observer";
import {
  forceClosePopup,
  systemsDashboardReceivedData,
} from "./observer-subjects";
import {
  MapboxData,
  MARKER_LAYER,
  CLUSTER_LAYER,
} from "./systems-dashboard-mapbox-data";
import { MapIntro, SELECTOR as MAP_INTRO_SELECTOR } from "./map-intro";
import { SystemsDashboardInfo } from "./systems-dashboard-info";

const INFO_WRAPPER_SELECTOR = ".js-info-wrapper";
const SUPPORT_WARNING_SELECTOR = ".js-support-warning";

const MAPBOX_TOKEN_ATTRIBUTE = "data-mapbox-token";
const RIVER_FEED_ATTRIBUTE = "data-feed-river";
const OCEAN_FEED_ATTRIBUTE = "data-feed-ocean";

const INTEGER_FIELDS = [
  "debris_extracted_last_30d",
  "debris_extracted_total",
  "kg_extracted",
];

/**
 * Get a label from a provided object.
 * If no label can be found, a fallback value is returned.
 */
const getLabelFromObject = (key, labels, fallbackValue) =>
  labels[key] ? labels[key] : fallbackValue;

/**
 * Merge datasets for both ocean an river systems.
 * generation_time and totals have both the same value in each API response,
 * the systems have to be merged into the same array.
 */
const mergeDataSets = (realtimeData) =>
  realtimeData.reduce(
    (collection, item) => {
      return {
        generation_time: item.generation_time,
        totals: item.totals,
        systems: [...collection.systems, ...item.systems],
      };
    },
    { systems: [] }
  );

/**
 * Note: The intro element was later abstracted away for re-use in other maps.
 * @TODO refactor element getter functions and/or move info to its own module?
 */
const SystemsDashboard = ({
  container,
  mapboxgl,
  map,
  sourceName,
  dateLabels,
  statusLabels,
}) => {
  const systemData = [...window.RIVER_SYSTEM_DATA, ...window.OCEAN_SYSTEM_DATA];

  let currentMarkerFeature;
  let currentMarkerNode;

  const isSourceLoaded = (source) =>
    map.getSource(source) && map.isSourceLoaded(source);
  const isLayerLoaded = (layer) => map.getLayer(layer);

  const mapIntro = new MapIntro(container.querySelector(MAP_INTRO_SELECTOR));

  /**
   * Get elements for intro/info, prevents having to specify lots of selectors.
   */
  const getElement = memoize((parentSelector, target) => {
    return target
      ? container.querySelector(`${parentSelector} [data-element="${target}"]`)
      : container.querySelector(parentSelector);
  });
  const getInfoElement = (target) => getElement(INFO_WRAPPER_SELECTOR, target);

  /**
   * Toggle info element, with helper show/hide functions.
   */
  const toggleInfo = (action) => {
    const hide = action === "hide";
    getInfoElement("close").setAttribute("aria-expanded", !hide);
    getInfoElement().setAttribute("aria-hidden", hide);

    if (hide) {
      // Force close all possible popups.
      publish(forceClosePopup);
    }
  };
  const showInfo = (hash) => {
    toggleInfo("show");
    window.location.hash = hash;
  };
  const hideInfo = () => {
    toggleInfo("hide");
    window.location.hash = "";
  };

  /**
   * Preserve clean copy of the info template to interpolate.
   */
  const getInfoTemplate = memoize(() => getInfoElement("content").outerHTML);

  /**
   * Normalize data (like prettifying integers).
   */
  const normalizeData = (data) => {
    return Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (key === "updated_at") {
          acc[key] = getRelativeTimeLabel(new Date(value)) || "unknown";
        } else if (key === "area_covered") {
          acc[key] = getIntegerLabel(value);
          const footballFieldSquareMeters = 5801;
          acc.in_football_fields = Math.floor(
            value / footballFieldSquareMeters
          );
        } else if (key === "status") {
          acc[key] = value;
          // Add a date_label property with the matching label (from the CMS).
          acc.date_label = getLabelFromObject(value, dateLabels, "Deployed at");
          acc.status_label = getLabelFromObject(value, statusLabels, value);
        } else if (INTEGER_FIELDS.includes(key)) {
          acc[key] = getIntegerLabel(value);
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {
        // Set default values for systems to handle both River and Ocean data.
        debris_extracted_last_30d: 0,
        debris_extracted_total: 0,
        area_covered: 0,
        kg_extracted: 0,
      }
    );
  };

  /**
   * Fetch realtime data not available in the CMS for a single systems.
   */
  const fetchRealtimeData = memoize((endpoint) => {
    return new Promise((resolve, reject) => {
      fetch(endpoint, { cache: "reload" })
        .then((response) => {
          if (!response.ok) {
            return reject(response);
          }
          return resolve(response.json());
        })
        .catch((error) => reject(error));
    });
  });

  /**
   * Fetch and combine all data (static and realtime).
   */
  const fetchAllData = () => {
    return new Promise((resolve, reject) => {
      const realTimeRiverData = fetchRealtimeData(
        container.getAttribute(RIVER_FEED_ATTRIBUTE)
      );
      const realTimeOceanData = fetchRealtimeData(
        container.getAttribute(OCEAN_FEED_ATTRIBUTE)
      );

      return Promise.all([realTimeRiverData, realTimeOceanData])
        .then((realtimeData) => {
          const mergedData = mergeDataSets(realtimeData);

          // Merge static and realtime data.
          const mergedSystems = mergedData.systems.map((system) => {
            const staticItemData = systemData.find((item) => {
              // item.id for ocean system is always 0, therefore compare bases on hash.
              return item.id === system.id || item.hash === system.id;
            });

            return normalizeData({
              ...staticItemData,
              ...system,
            });
          });

          resolve({
            ...mergedData,
            systems: mergedSystems,
          });
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Interpolate static and realtime data and update the template.
   */
  const setCurrentInfo = (featureId) => {
    fetchAllData().then(({ systems }) => {
      // item.id for ocean system is always 0, therefore compare bases on hash.
      const data = systems.find(
        (item) => item.id === featureId || item.hash === featureId
      );

      const templateNode = SystemsDashboardInfo({
        template: getInfoTemplate(),
        data,
      });

      const docFragment = new DocumentFragment();
      [...templateNode.children()].map((childNode) => {
        docFragment.appendChild(childNode);
      });

      // Empty element before appending
      getInfoElement("content").innerHTML = "";
      getInfoElement("content").appendChild(docFragment);
      getInfoElement().scrollTop = 0;
    });
  };

  /**
   * Reset the current marker.
   */
  const resetCurrentMarkerFeature = () => {
    if (!currentMarkerFeature) {
      return;
    }
    map.removeFeatureState({ source: sourceName, id: currentMarkerFeature.id });
    currentMarkerNode.parentNode.removeChild(currentMarkerNode);
    currentMarkerNode = null;
    currentMarkerFeature = null;
  };

  /**
   * Set the current marker feature.
   */
  const setCurrentMarkerFeature = (feature) => {
    resetCurrentMarkerFeature();
    map.setFeatureState(
      { source: sourceName, id: feature.id },
      { active: true }
    );
    currentMarkerFeature = feature;
    currentMarkerNode = htmlToElement(`
      <div class="systems-dashboard__active-marker"></div>
    `);
    new mapboxgl.Marker(currentMarkerNode)
      .setLngLat(feature.geometry.coordinates)
      .addTo(map);
  };

  /**
   * Set the current marker.
   */
  const setCurrentMarker = (feature) => {
    const coordinates = feature.geometry.coordinates.slice();

    // Offset the map a bit, due to the info element popping up.
    const xOffset = matchesBreakpoint("small") ? 2 : getDocWidth() / 230;

    map.flyTo({
      center: [coordinates[0] + xOffset, coordinates[1]],
      zoom: 6,
      speed: 1,
    });

    setCurrentMarkerFeature(feature);
    setCurrentInfo(feature.properties.id);
    mapIntro.hide();
    showInfo(feature.properties.hash);
  };

  /**
   * Listener for cluster clicks.
   */
  const clusterClickHandler = (e) => {
    const feature = e.features[0];
    const clusterId = feature.properties.cluster_id;
    map
      .getSource(sourceName)
      .getClusterExpansionZoom(clusterId, (error, zoom) => {
        if (error) {
          return;
        }
        map.easeTo({ center: feature.geometry.coordinates, zoom });
      });
  };

  /**
   * Listener for marker clicks.
   */
  const markerClickHandler = (e) => setCurrentMarker(e.features[0]);

  /**
   * Listener for map clicks (to reset marker focus state).
   */
  const mapClickHandler = (e) => {
    if (!map.getLayer(MARKER_LAYER)) {
      return;
    }
    const features = map.queryRenderedFeatures(e.point, {
      layers: [MARKER_LAYER],
    });
    if (!features.length) {
      hideInfo();
      // Re-center the last active marker on mobile due to closing info popup.
      if (!matchesBreakpoint("small") && currentMarkerFeature) {
        const coordinates = currentMarkerFeature.geometry.coordinates.slice();
        map.panTo(coordinates, {
          duration: 500,
        });
      }
      resetCurrentMarkerFeature();
    }
    mapIntro.hide();
  };

  /**
   * Listener for map mouse events (to set mouse pointer).
   */
  const mapMouseMoveHandler = (e) => {
    if (!map.getLayer(CLUSTER_LAYER) || !map.getLayer(MARKER_LAYER)) {
      return;
    }
    const features = map.queryRenderedFeatures(e.point, {
      layers: [CLUSTER_LAYER, MARKER_LAYER],
    });
    map.getCanvas().style.cursor = features.length ? "pointer" : "";
  };

  /**
   * Attach event listeners to the Mapbox map.
   */
  const attachMapListeners = () => {
    map.on("mousemove", mapMouseMoveHandler);
    map.on("click", CLUSTER_LAYER, clusterClickHandler);
    map.on("click", MARKER_LAYER, markerClickHandler);
    map.on("click", mapClickHandler);
  };

  /**
   * Attach click listeners to UI.
   */
  const attachClickListeners = () => {
    getInfoElement("close").addEventListener(
      "click",
      preventingDefault((e) => {
        hideInfo();
        resetCurrentMarkerFeature();
        getInfoElement("content").innerHTML = "";
      })
    );
  };

  /**
   * Open initial system when opened via URL.
   * We'll have to wait until the source and layer are loaded.
   */
  const openInitialSystemFromHash = (hash) => {
    if (!hash) {
      return;
    }

    // Zoom to a level where we can be sure all features have a hash as a property.
    // Clusters don't have hashes.
    map.flyTo({
      zoom: 6,
      speed: 1,
    });

    const getAndSetFeature = () => {
      const features = map.querySourceFeatures(sourceName, {
        sourceLayer: MARKER_LAYER,
      });
      const feature = features.find((f) => f.properties.hash === hash);
      if (!feature) {
        return;
      }
      setCurrentMarker(feature);
    };

    const isLoaded = () =>
      isSourceLoaded(sourceName) && isLayerLoaded(MARKER_LAYER);
    const loadOrWait = () =>
      isLoaded() ? getAndSetFeature() : map.once("render", loadOrWait);

    loadOrWait();
  };

  return {
    init() {
      attachMapListeners();
      attachClickListeners();

      // Initialize generic map intro module.
      mapIntro.init();

      // Preload the realtime data.
      fetchAllData().then((realtimeData) => {
        publish(systemsDashboardReceivedData, realtimeData);
      });

      // Open initial system if it's specified in the URL.
      openInitialSystemFromHash(window.location.hash.substr(1));

      // Expose map for easier debugging.
      window.SYSTEMS_DASHBOARD = map;
    },
  };
};

export const enhancer = (container) => {
  const dateLabels = JSON.parse(container.getAttribute("data-date-labels"));
  const statusLabels = JSON.parse(container.getAttribute("data-status-labels"));

  waitForGlobal("mapboxgl", 100).then((mapboxgl) => {
    if (mapboxgl.supported()) {
      // Set up Mapbox data, layers and markers.
      const token = container.getAttribute(MAPBOX_TOKEN_ATTRIBUTE);
      const mapboxData = new MapboxData({ container, mapboxgl, token });
      // Init interactivity handling.
      mapboxData.init().then(({ map, sourceName }) => {
        const dashboard = new SystemsDashboard({
          container,
          map,
          mapboxgl,
          sourceName,
          dateLabels,
          statusLabels,
        });
        dashboard.init();
      });
    } else {
      // Show a warning if Mapbox is not supported.
      const warning = document.querySelector(SUPPORT_WARNING_SELECTOR);
      warning.setAttribute("aria-hidden", "false");
    }
  });
};
