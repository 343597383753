import Cookies from "js-cookie";
import CookieConsent from "@grrr/cookie-consent";
import { takeLast } from "@grrr/utils";
import { pushEvent } from "./gtm-event";

const COOKIE_CONSENT_COOKIE_NAME = "toc_cookie_consent";

/**
 * Called by listener.
 * Update Tag Manager.
 */
const handleUpdate = (cookies) => {
  const acceptedCookie = cookies.find((cookie) => cookie.accepted);

  // Only send push event when there are accepted cookies.
  if (acceptedCookie) {
    pushEvent({
      event: "cookieConsent",
      cookieConsent: acceptedCookie.id,
    });
  }

  // Set actual cookie to share consent-level with 'products' subdomain.
  const rootDomain = takeLast(2, window.location.hostname.split(".")).join(".");
  Cookies.set(
    COOKIE_CONSENT_COOKIE_NAME,
    `${acceptedCookie ? acceptedCookie.id : ""}`,
    {
      expires: 365,
      domain: rootDomain,
      secure: window.location.protocol === "https:",
    }
  );
};

export default () => {
  // Bail out if there is no config.
  if (!window.COOKIE_CONSENT_CONFIG) {
    return;
  }

  // An optional cookie might be present from the 'products' subdomain.
  // If so, settings in the cookie are leading, and we will manipulate
  // localStorage before initializing the CookieConsent module.
  const existingCookieChoice = Cookies.get(COOKIE_CONSENT_COOKIE_NAME);
  const cookieChoiceIsValid = window.COOKIE_CONSENT_CONFIG.cookies.find(
    (cookieOption) => cookieOption.id === existingCookieChoice
  );
  if (cookieChoiceIsValid && window.localStorage) {
    localStorage.setItem(
      "cookie-consent-preferences",
      JSON.stringify(
        window.COOKIE_CONSENT_CONFIG.cookies.map((cookie) => ({
          id: cookie.id,
          accepted: cookie.id === existingCookieChoice,
        }))
      )
    );
  }

  // Construct and initialize the module.
  const cookieConsent = CookieConsent(window.COOKIE_CONSENT_CONFIG);

  // Update Tag Manager when `update` event is fired.
  cookieConsent.on("update", handleUpdate);

  // Make the object globally available.
  window.CookieConsent = cookieConsent;
};

/**
 * Set optimal cookies programmatically.
 */
export const handler = (el, e) => {
  e.preventDefault();

  // Bail out if there is no config.
  if (!window.COOKIE_CONSENT_CONFIG) {
    return;
  }

  // Update existing cookies to ensure optimal cookies are set.
  const { cookies } = window.COOKIE_CONSENT_CONFIG;

  const newCookies = cookies.map((item) =>
    item.id === "optimal"
      ? {
          ...item,
          accepted: true,
        }
      : {
          ...item,
          accepted: false,
        }
  );

  // Set cookies programmatically.
  window.CookieConsent.updatePreference(newCookies);
  window.CookieConsent.hideDialog();
};
