const resizeElement = (element, height) => () => {
  element.style.height = `${height}px`;
};

const handleWindowMessage = (element) => (event) => {
  if (parseInt(event.data.frameHeight, 10)) {
    window.requestAnimationFrame(
      resizeElement(element, event.data.frameHeight)
    );
  }
};

export const enhancer = (element) => {
  window.addEventListener("message", handleWindowMessage(element));
};
