import { selfOrClosest } from "./util";

/**
 * Handles the clicks on a info-tab item, and shows the pane
 */

export const handler = (el, e) => {
  const target = selfOrClosest(e.target, '[role="tab"]');
  if (!target) {
    return;
  }
  e.preventDefault();

  // Old tab and tabpanel.
  el.querySelector('[aria-selected="true"]').setAttribute(
    "aria-selected",
    "false"
  );
  el.querySelector('[aria-hidden="false"]').setAttribute("aria-hidden", "true");

  // New tab and tabpanel.
  target.setAttribute("aria-selected", true);
  el.querySelector(`#${target.getAttribute("aria-controls")}`).setAttribute(
    "aria-hidden",
    "false"
  );
};
